var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"central"},[_c('v-row',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"central__title"},[_c('h1',{staticClass:"font-weight-light ml-4 pointer",staticStyle:{"font-size":"1.1em"}},[_vm._v("Schowek")])])]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"central__card",attrs:{"height":"calc(100vh - 185px)"}},[_c('v-container',[_c('v-data-table',{staticClass:"mt-4 central__table",attrs:{"items":_vm.prefixedItems(_vm.getStorageAttachments),"headers":_vm.getStorageHeaders,"fixed-header":"","height":"calc(100vh - 365px)","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[(item.fakeFileName &&
                    ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(
                        item.fakeFileName.split('.').pop()
                    )
                )?_c('td',[_c('a',{staticClass:"font-weight-regular body-2",attrs:{"href":_vm.webdavUrl(_vm.getGlobalPaths.webdav, item.fakeFileName),"download":""}},[_vm._v(_vm._s(item.realFileName))])]):_c('td',[_c('a',{staticClass:"font-weight-regular body-2",attrs:{"href":("" + (_vm.getGlobalPaths.attachments) + (item.fakeFileName)),"target":"_BLANK"}},[_vm._v(_vm._s(item.realFileName))])]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.size ? _vm.correctSize(item.size) : ''))]),_c('td',{attrs:{"align":"right"}},[(item.prefix)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"},on:{"click":function($event){return _vm.openOfficePreview({
									url: ("" + (_vm.getGlobalPaths.webdav) + (item.fakeFileName)),
								})}}},[_vm._v("mdi-magnify")]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.send(item._id)}}},on),[_vm._v("mdi-file-send")])]}}],null,true)},[_c('span',[_vm._v("Wyślij do schowka użytkownika")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.remove(item._id)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Usuń ze schowka")])])],1)])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }