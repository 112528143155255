<template>
    <v-container class="central">
        <v-row class="pa-4">
            <div class="d-flex justify-space-between" style="width: 100%">
                <div class="central__title">
                    <h1 style="font-size: 1.1em" class="font-weight-light ml-4 pointer">Schowek</h1>
                </div>
            </div>
            <v-col cols="12" md="12">
                <v-card class="central__card" height="calc(100vh - 185px)">
                    <v-container>
                        <v-data-table
                            :items="prefixedItems(getStorageAttachments)"
                            :headers="getStorageHeaders"
                            fixed-header
                            height="calc(100vh - 365px)"
                            class="mt-4 central__table"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:item="{item}">
                                <tr>
                                    <td
                                        v-if="item.fakeFileName &&
                    ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(
                        item.fakeFileName.split('.').pop()
                    )
                "
                                    >
                                        <a
                                            :href="webdavUrl(getGlobalPaths.webdav, item.fakeFileName)"
                                            download
                                            class="font-weight-regular body-2"
                                        >{{ item.realFileName }}</a>
                                    </td>

                                    <td v-else>
                                        <a
                                            :href="`${getGlobalPaths.attachments}${item.fakeFileName}`"
                                            target="_BLANK"
                                            class="font-weight-regular body-2"
                                        >{{ item.realFileName }}</a>
                                    </td>

                                    <td>{{item.description}}</td>
                                    <td align="left">{{item.size ? correctSize(item.size) : ''}}</td>
                                    <td align="right">
                                        <v-icon
                                            size="18"
                                            class="mr-2"
                                            @click="
								openOfficePreview({
									url: `${getGlobalPaths.webdav}${item.fakeFileName}`,
								})"
                                            v-if="item.prefix"
                                        >mdi-magnify</v-icon>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    class="mr-2"
                                                    v-on="on"
                                                    @click="send(item._id)"
                                                    small
                                                >mdi-file-send</v-icon>
                                            </template>
                                            <span>Wyślij do schowka użytkownika</span>
                                        </v-tooltip>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    v-on="on"
                                                    @click="remove(item._id)"
                                                    small
                                                    color="red"
                                                >mdi-delete</v-icon>
                                            </template>
                                            <span>Usuń ze schowka</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Vue from "vue";
import { VueContext } from "vue-context";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {},
    data: () => ({}),
    methods: {
        ...mapActions([
            "removeAttachmentFromStorage",
            "openModal",
            "openSecondModal",
            "addPrefixToAttachment",
        ]),
        ...mapMutations(["setStorageAttachment", "openOfficePreview"]),
        remove(attachmentID) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { attachmentID: attachmentID },
                type: "storage",
            });
        },
        send(attachment) {
            this.setStorageAttachment(attachment);
            this.openModal({
                headLine: "Wyślij do użytkownika",
                open: true,
                width: 600,
                route: "resources",
                tab: "storage",
                global: "storage",
            });
        },
        correctSize(size) {
            return size < 1024
                ? size + " [B]"
                : size >= 1024 * 1024
                ? this.betterRound(size / (1024 * 1024)) + " [MB]"
                : this.betterRound(size / 1024) + " [KB]";
        },
        betterRound(num) {
            return Math.round((num + Number.EPSILON) * 100) / 100;
        },
        webdavUrl(url, file) {
            let prefix = "";
            if (["doc", "docx"].includes(file.split(".").pop()))
                prefix = `ms-word:ofe|u|`;
            else if (["xls", "xlsx"].includes(file.split(".").pop()))
                prefix = `ms-excel:ofe|u|`;
            else if (["ppt", "pptx"].includes(file.split(".").pop()))
                prefix = `ms-powerpoint:ofe|u|`;

            return `${prefix}${url}${file}`;
        },
        prefixedItems(payload) {
            payload.forEach((element) => {
                if (element.fakeFileName) {
                    if (
                        element.fakeFileName.includes(".doc") ||
                        element.fakeFileName.includes(".docx")
                    ) {
                        element.prefix = `ms-word:ofe|u|`;
                    } else if (
                        element.fakeFileName.includes(".xls") ||
                        element.fakeFileName.includes(".xlsx")
                    ) {
                        element.prefix = `ms-excel:ofe|u|`;
                    } else if (
                        element.fakeFileName.includes(".ppt") ||
                        element.fakeFileName.includes(".pptx")
                    ) {
                        element.prefix = `ms-powerpoint:ofe|u|`;
                    } else {
                        element.prefix = "";
                    }
                }
            });
            return payload;
        },
    },
    computed: {
        ...mapGetters([
            "getStorageAttachments",
            "getStorageHeaders",
            "getStorageAttachment",
            "getStorageAttachment",
            "getGlobalPaths",
        ]),
    },
};
</script>